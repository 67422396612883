import React, { useEffect } from 'react';

const WebChat = ({ language, inputTextFieldHint }) => {
    useEffect(() => {
        localStorage.clear();
        if (!document.getElementById('rasa-webchat-script')) {
            const script = document.createElement('script');
            script.id = 'rasa-webchat-script';
            script.src = '/rasa-webchat@1.x.x.js';
            script.async = true;
            script.onload = () => {
                initializeWebChat(language, inputTextFieldHint);
            };
            document.head.appendChild(script);
        } else {
            initializeWebChat(language, inputTextFieldHint);
        }
    }, [language, inputTextFieldHint]);

    const initializeWebChat = (language, hint) => {
        window.WebChat.default(
            {
                title: 'TMDHC Chatbot',
                subtitle: '',
                initPayload: `/set_data{"language":"${language}"}`,
                customData: { language: language },
                socketUrl: process.env[`REACT_APP_SOCKET_URL_${language.toUpperCase()}`],
                inputTextFieldHint: hint,
                showFullScreenButton: false,
                embedded: true,
                params: {
                    storage: 'local',
                },
            },
            null
        );
    };

    return <div></div>;
};

export default WebChat;
