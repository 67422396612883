import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WebChat from './components/WebChat';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/en" element={<WebChat language="en" inputTextFieldHint="Type a message..." />} />
                <Route path="/tc" element={<WebChat language="tc" inputTextFieldHint="輸入訊息" />} />
                <Route path="/sc" element={<WebChat language="sc" inputTextFieldHint="输入讯息" />} />
                <Route path="/" element={<WebChat language="en" inputTextFieldHint="Type a message..." />} />
            </Routes>
        </Router>
    );
};

export default App;
